@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Sanchez&display=swap');

body {
  /* font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans';
  overflow-y: scroll;
}

/* code {
  font-family: Roboto, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

* {
  box-sizing: border-box;
}
