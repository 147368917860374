.otp_input {
  max-width: 45px;
  min-height: 45px;
  text-align: center;
  border: 1px #000 solid;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 6px;
}
.otp_input[type='number'] {
  -moz-appearance: textfield;
}
.otp_input::-webkit-outer-spin-button,
.otp_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp_group {
  display: flex;
  justify-content: space-between;
}
